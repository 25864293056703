
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/en/blog",
      function () {
        return require("private-next-pages/en/blog.mdx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/en/blog"])
      });
    }
  